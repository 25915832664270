import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setThemeState } from "../../features/theme/themeSlice";
// import {  useParams } from "react-router-dom";

import logo from "./../../img/logo.svg";
import logoDark from "./../../img/logo-dark.svg";
import bgLeft from "./../../img/bg-left.svg";
import bgRight from "./../../img/bg-right.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

  const [empty, setEmpty] = useState(true);
  const [state, setState] = useState({});

  useEffect(() => {
    if (sessionStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
      dispatch(setThemeState(true));
    } else {
      document.documentElement.classList.remove("dark");
      dispatch(setThemeState(false));
    }
  }, []);

  useEffect(() => {
    if (!emailRegex.test(state["type"])) {
      // invalid email
      setEmpty(true);
    } else {
      // valid email
      setEmpty(false);
    }
  }, [state]);

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/admin');
  };

  return (
    <section className="login forgot-password">
      <div className="container max-w-[1010px] mx-auto px-4 relative z-10 pt-20 md:pt-28 2xl:pt-48 pb-24 md:pb-36 xl:pb-32">
        <div className="wrapper flex flex-col items-center md:gap-12 lg:gap-24 md:flex-row md:items-start md:justify-center 2xl:justify-between">
          <div className="flex flex-col w-full shrink mb-11 items-center md:items-start md:mb-0 md:w-fit">
            <NavLink to={"/"} className="flex mb-6 md:mb-9">
              <div className="flex object-contain">
                <img
                  src={isDarkMode ? logoDark : logo}
                  className="logo h-[48px] w-auto"
                  width={"110"}
                  height={"52"}
                  alt="logo"
                />
              </div>
            </NavLink>

            <h1 className="mb-4 font-Author text-center md:text-start text-3xl leading-8 font-semibold text-blue">
              Forgot Password?
            </h1>
            <p className="max-w-[360px] text-sm font-semibold">
              Enter the email address you used when you joined and we’ll send
              you instructions to reset your password.
            </p>
          </div>

          <form className="max-w-[450px] w-full flex flex-col items-center" onSubmit={handleSubmit}>
            <div className="form-wrap w-full flex flex-col items-start p-5 sm:p-8 border border-lightBlue dark:border-blue  rounded-lg bg-white dark:bg-themeBlack">
              <div className="w-full flex flex-col mb-5">
                <label className="mb-2 text-[14px] text-blackPrimary dark:text-themeWhite font-normal">
                Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  required
                  value={inputs.email || ""}
                  onChange={handleChange}
                  className="focus:border-blue h-[50px] px-6 pb-[2px] border-[1px] border-gray rounded-lg outline-none placeholder:text-blackPrimary07 placeholder:text-[16px] text-[16px] text-blackPrimary font-normal bg-white"
                />
              </div>              

              <button
                type="submit"
                className="bg-blue hover:bg-darkBlue font-Author font-semibold text-lg text-themeWhite transition-colors h-[54px] px-2 w-full flex justify-center items-center rounded-xl"
              >
                Send reset instruction
              </button>
            </div>

            <NavLink
              to={"/login"}
              className="mt-12 font-semibold underline underline-offset-4 hover:text-blue transition-colors dark:text-themeWhite"
            >
              Return to login
            </NavLink>
          </form>
        </div>
      </div>

      <img
        src={bgLeft}
        className="absolute z-[-1] left-0 top-[550px] md:top-[400px] h-[515px] w-auto"
        width={428}
        height={236}
        alt="bg"
      />
      <img
        src={bgRight}
        className="absolute z-[-1] right-0 top-[-190px] lg:top-[150px] h-[515px] w-auto"
        width={428}
        height={236}
        alt="bg"
      />
    </section>
  );
};

export default ForgotPassword;
